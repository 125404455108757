.bill-receipt {
  width: auto;
  margin: 50px auto;
  background: #fafafa;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: fit-content;

    @media only screen and (max-width: 768px) {
      > .button {
        max-width: fit-content !important;
        max-height: 42px !important;
        padding: 0 16px !important;
      }
    }
  }

  &__recurring-payment-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: fit-content;

    @media only screen and (max-width: 768px) {
      > .button {
        max-width: fit-content !important;
        max-height: 42px !important;
        padding: 0 16px !important;
      }
    }
  }

  &__row {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    border-bottom: 1px #f5ecec solid;

    &:last-child {
      border-bottom: none !important;
      padding-bottom: 0px !important;
    }

    & > .field-label {
      font-style: normal;
      font-size: 18px;
      line-height: 144%;
      color: #000000;
    }

    & > .field-value {
      font-style: normal;
      font-size: 18px;
      line-height: 144%;
      text-align: right;
      color: #000000;
    }

    & > .sub-field-value {
      font-size: 14px;
      line-height: 144%;
      text-align: right;
      color: #ccc;
      text-transform: uppercase;
    }

    & .multi-value {
      & > .field-value {
        font-size: 14px;
        line-height: 144%;
        text-align: right;
        color: #121020;
      }
      & > .sub-field-value {
        font-size: 11px;
        line-height: 144%;
        text-align: right;
        color: #605c78;
        text-transform: uppercase;
      }
    }
  }
}

.sub-field-value {
  font-size: 11px;
  line-height: 144%;
  color: #898989;
  text-transform: uppercase;
}

.amount-field {
  font-size: 18px;
  line-height: 144%;
  color: #121020;
}

.print-body {
  width: 100%;
  height: 120px;
  background: #00008034;
  background-blend-mode: multiply;
}

ion-icon[name="copy-outline"] {
  color: $primaryTextColor;
}

.recurring-payment_delete {
  display: flex;
  width: 52px;
  height: 52px;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  background: #fdf0ee;
  border: none;
  cursor: pointer;
}

@media print {
  body {
    font-size: 20px; /* Increase the font size for print */
    line-height: 1.8; /* Adjust line height for readability */
  }

  h1 {
    font-size: 28px !important;
  }

  p {
    font-size: 18px !important;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  .yes-print,
  .yes-print * {
    display: block !important;
  }

  /* Ensure flex styles are explicitly applied for print */
  .bill-receipt__row {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    border-bottom: 1px #f5ecec solid;
    padding: 8px 0px;
  }

  .bill-receipt__row .field-label {
    font-size: 18px !important;
  }

  .bill-receipt__row  .field-value {
    text-align: right !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }

  .print-receipt-footer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    padding: 8px 16px;
    background: #f9f9f9;
    border-radius: 8px;
  
    strong {
      display: inline-block !important;
    }

    h1 {
      font-size: 20px !important;
    }
  }
}
