.edit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16.8px;
  height: 24px;
  background: #e8e8ff;
  border-radius: 26px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 144%;
  text-align: center;
  color: $primaryTextColor;
  outline: none;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: $hoverColorlite;
  }
}
