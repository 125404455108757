.MainPage {
  padding: 0;
  height: 100% !important;
  &__layout {
    padding: 0;
  }
  &__content {
    padding: 16px 40px;
    min-height: 280px;
    background: $bgColor;
  }
  &__side {
    background: pink !important;
    min-width: 260px !important;
    height: 1024px !important;
    border: none !important;
  }
  &__menu {
    // color: red;
    background: transparent !important;
    border: none !important;
    &_item {
      padding-left: 24px !important;
      span {
        height: fit-content;
        font-size: 16px !important;
      }
      // ion-icon {
      //   float: right;
      //   height: 40px;
      //   font-size: 18px;
      // }
    }
  }
  .MainPage__layout {
    height: 100% !important;
  }
  .sapa-menu__logo{
    width: 100%;
    background:#FAFAFA !important;
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
    img {
      width: 40px;
      margin-left: -24px;
    }
  }
  .hover-bar {
    float: left;
    height: 57px;
    width: 6px;
    background-color: $primaryTextColor;
  }
  .invisible-bar {
    float: left;
    height: 57px;
    width: 6px;
    background-color: #FAFAFA;
  }
}
