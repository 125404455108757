.Transactions {
  background-color: $bgColor;
  .sapa-transactions__container {
    margin-top: 24px;
    margin-bottom: 47.72px !important;
    border-radius: 0 !important;
    border: none !important;
    height: fit-content !important;
    .ant-card-bordered {
      border: none !important;
    }
    .ant-card-body {
      padding: 0 !important;
    }
  }
}
.sapa-transactions-table__showbtn_container {
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  margin-top: 32px;
  margin-bottom: 24px;
  button {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 144%;
    text-align: center;
    color: #000080;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 87px;
    height: 24px;
    background: #FFFBE7;
    border-radius: 26px;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {
      background-color: #e6d8f7;
    }
  }
}
