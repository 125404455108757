$bgColor: #FEFEFE;
$sapaBlack: #121020;
$textColor: #2c264b;
$primaryTextColor: #000080;
$primaryHue: #00008020;
$white: #fff;
$sapaGrey: #F5F5F5;
$hoverColor: #FFFBE7;
$hoverColorlite:  rgb(232, 232, 255);
$hoverColorDark: #000080;

$borderRadius: 10px;


// fonts
$cg-regular: "Cabinet Grotesk";
$cg-medium: "Cabinet Grotesk Medium";
$cg-bold: "Cabinet Grotesk Bold";
$gs-regular: "General Sans";
$gs-medium: "General Sans Medium";
$gs-semi-bold: "General Sans Semi Bold";
$gs-bold: "General Sans Bold";
