.Transaction-modal {
  height: 100%;
  .modal-avatar {
    width: 72px;
    height: 72px;
    border-radius: 60% !important;
    filter: drop-shadow(0px 8px 20px rgba(177, 177, 177, 0.1));
    margin-bottom: 2px;
  }
  .transaction-modal-list__container {
    display: flex;
    height: 90%;
    flex-direction: column;
    justify-content: space-between;
    .ant-typography {
      margin: 0 !important;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 8px;
      div {
        margin: 0 !important;
        height: 16px !important;
      }
    }
    span {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.5px solid #eae9ed;
      &:last-child {
        border-bottom: none;
      }
      p {
        font-family: "General Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 144%;
        color: #56516f;
        margin: 0 !important;
        padding: 12px 0 16px;
      }
      .modal-list-dark-text {
        font-weight: 600 !important;
        color: #121020 !important;
        text-align: right !important;
        font-size: 14px !important;
      }
      .modal-list-success-text {
        font-weight: 600 !important;
        color: #318d1f !important;
      }

      .modal-list-failed-text {
        font-weight: 600 !important;
        color: #f04438 !important;
      }
    }
  }
  .modal-list-long {
    padding: 12px 0 16px;
  }
  pre {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 144%;
    color: #56516f;
    margin: 0 !important;
    padding: 0 !important;
    text-align: right;
  }
  .transaction-modal-btn__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    height: 40px !important;
    margin-top: 24px;
    button {
      height: 40px !important;
      &:first-child {
        width: 113px !important;
        span {
          font-size: 14px !important;
          text-align: center;
        }
      }
      &:last-child {
        width: 95px !important;
        border: 1px solid #f04438;
        &:hover {
          background-color: #e6cfce4b !important;
        }
        span {
          color: #f04438;
          font-size: 14px !important;
        }
      }
    }
  }

  .ant-tabs-tabpane {
    height: 100% !important;
  }
  .ant-tabs-content-top {
    height: 100% !important;
  }

  @media screen and (max-height: 1080.55px) {
  }
}
