.ApiProviders {
  background-color: $bgColor;
  .sapa-apiproviders__container {
    margin-top: 24px;
    margin-bottom: 47.72px !important;
    border-radius: 0 !important;
    border: none !important;
    height: fit-content !important;
    .ant-card-bordered {
      border: none !important;
    }
    .ant-card-body {
      padding: 0 !important;
    }
  }
}

.Overview {
  .api-overview__container {
    border-radius: 0 !important;
    border: none !important;
    height: fit-content !important;
    .ant-card-bordered {
      border: none !important;
    }
    .ant-card-body {
      padding: 0 !important;
    }
    .api-overview__tab {
      display: flex;
      flex-direction: row;
      padding: 0px;
      width: 183px;
      height: 24px;
      margin-bottom: 32px;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "General Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        padding: 12px 0;
        line-height: 144%;
        text-align: center;
        background-color: #ffffff;
        border: 1px solid $primaryTextColor;
        color: $primaryTextColor;
        cursor: pointer;
        &:first-child {
          width: 92px;
          border-radius: 26px 0px 0px 26px;
          border-right: none;
        }
        &:last-child {
          width: 91px;
          border-radius: 0px 26px 26px 0px;
        }
        &:hover {
          background-color: $hoverColor;
        }
      }
      button.active {
        background-color: $primaryTextColor;
        color: #ffffff;
      }
    }
    .api-overview__dashboard-card-container {
      display: flex;
      justify-content: flex-start;
      gap: 24px;
      margin-bottom: 32px;
      .dashboard-card__container {
        width: 25% !important;
        .ant-card-bordered {
          border: 1px solid #FFFBE7 !important;
          box-shadow: 0px 2px 40px rgba(177, 177, 177, 0.05);
          border-radius: 12px;
        }
        .ant-card-body {
          padding: 20px !important;
        }
        .dashboard-card__container_inner {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
      .dashboard-card__topSection {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      .dashboard-card__topSection_icon {
        width: 16px;
        height: 16px;
      }
      .sapa-dashboard-card__container {
        display: flex;
        flex-direction: row;
        gap: 24px;
      }
    }
  }
}
