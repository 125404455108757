.input {
  // flex-direction: row;
  // align-items: center;
  // padding: 15px 24px;
  padding-inline: 15px 20px;
  gap: 8px;
  width: "100%";
  height: 49px;
  background: #f5f5f5;
  border-radius: 26px;
  font-family: "General Sans";
  margin-top: 10px;
  // padding-right: 45px;
  &::placeholder {
    font-family: "General Sans";
    font-weight: 400;
    line-height: 144%;
    color: #b1b1b1;
  }
  &_wrapper {
    // max-width: 380px;
    position: relative;
  }

  &__icon_right {
    position: absolute;
    right: 15px;
    top: 24px;
  }

  &__bottom_label {
    font-weight: bold;
    font-size: 11px;
    color: #318d1f;
    text-transform: uppercase;
    margin-top: 4px;
  }
}
