.ant-btn.ant-btn-round {
  border-radius: 32px !important;
}

.ant-modal-mask {
  background-color: #0000800f !important;
}

// .ant-modal-content {
//   // padding: 60px 60px !important;
// }

.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  height: 57px;
  line-height: 40px;
  padding-inline: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-inline: 0px;
  margin-bottom: 20px !important;
  margin-block: 0px;
  width: auto;
  border-radius: 0;
}

.ant-menu-inline .ant-menu-item:first-child,
.ant-menu-vertical .ant-menu-item:first-child,
.ant-menu-inline .ant-menu-submenu-title:first-child,
.ant-menu-vertical .ant-menu-submenu-title:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.ant-menu-inline .ant-menu-item:last-child,
.ant-menu-vertical .ant-menu-item:last-child,
.ant-menu-inline .ant-menu-submenu-title:last-child,
.ant-menu-vertical .ant-menu-submenu-title:last-child {
  border-bottom-right-radius: $borderRadius;
  border-bottom-left-radius: $borderRadius;
}

.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active,
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: #FFFBE7;
  color: $primaryTextColor;
  font-weight: bold;
}

.ant-layout-sider-children {
  background: #fafafa !important;
}

.ant-menu-light {
  border-radius: 0;
  border: none !important;
}

.ant-menu-item-selected .ant-menu-title-content {
  color: $primaryTextColor;
  font-weight: bold;
}

.ant-menu-title-content {
  margin-inline-start: 16px !important;
  // opacity: 1;
  // transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s,
  //   color 0.3s;
  text-transform: capitalize;
}

// dropdown
/* .ant-select-selector {
  width: 380px !important;
  height: 48px !important;
  background: #f5f5f5 !important;
  border-radius: 26px !important;
  padding: 7px 18px !important;
}
 */
.ant-select-item-option-selected {
  background: $primaryHue !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  &:hover,
  :focus {
    border: 1px solid #000080 !important;
  }
}
.ant-dropdown-placement-top ul {
  margin-top: 62px !important;
}

// checkbox
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primaryTextColor;
  border-color: $primaryTextColor;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: $primaryTextColor !important;
}

.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: $primaryTextColor !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: $primaryTextColor;
}

.ant-checkbox-checked:after {
  border: 2px solid $primaryTextColor;
}

.ant-checkbox-checked:after:hover {
  border: 2px solid $primaryTextColor;
}

.ant-input {
  background: $bgColor;
}

//input override
.ant-form-item-label > label.ant-form-item-required::before {
  content: unset !important;
}
.ant-input-affix-wrapper {
  border-radius: 26px !important;
  width: 380px !important;
  height: 40px !important;
  background-color: $sapaGrey !important;
  border: none !important;
  outline: none !important;
}
.ant-input-affix-wrapper:hover {
  border: none !important;
  outline: none !important;
}
.ant-input-affix-wrapper:focus {
  border: none !important;
  outline: none !important;
}
.ant-input-affix-wrapper-focused {
  outline: none !important;
  border: none !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-enabled):hover {
  outline: none !important;
  border: none !important;
}
.ant-input-affix-wrapper-focused:focus {
  outline: none !important;
  border: none !important;
}

//statistics
.ant-statistic-title {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 144%;
  color: #898989 !important;
  margin: 0 !important;
}
.ant-statistic-content {
  width: fit-content !important;
  height: fit-content !important;
  padding: 0 !important;
  margin: 0 !important;
}
.ant-statistic-content-value {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 800 !important;
  font-size: 20px !important;
  line-height: 144%;
  text-align: center;
  color: #000000 !important;
}

//dropdown override
.ant-dropdown {
  width: 153px !important;
  height: 179.61px !important;
}
.ant-dropdown .ant-dropdown-menu {
  padding: 6.65px 0 !important;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 6.65px 13.3px !important;
}
.ant-dropdown-menu-item:hover {
  background-color: #FFFBE7 !important;
}
.ant-dropdown-menu-item-selected {
  background-color: #FFFBE7 !important;
  color: black !important;
}

//table override
.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 0 !important;
  // padding: 20px 38.67px 20px 24px !important;
  padding-left: 24px !important;
  // padding-right: 34px !important;
}

.ant-table-wrapper .ant-table-column-sorters {
  display: flex;
  justify-content: center !important;
  align-items: center;
  gap: 10px;
  width: fit-content !important;
  margin: 0 !important;
  .ant-table-column-sorter {
    margin: 0 !important;
  }
}

th {
  padding: 24px 0 !important;
  text-align: start !important;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px !important;
  line-height: 144%;
  color: $sapaBlack;
}
// th:nth-child(2) {
//   width: 158px;
//   // padding-right: 20px !important;
//   div {
//     margin: 0 !important;
//   }
// }
// th:nth-child(3) {
//   padding-right: 10px !important;
// }
// th:last-child {
//   padding: 24px 20px 24px 0 !important;
// }
.ant-table-column-title {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 11px;
  line-height: 144%;
  color: $sapaBlack;
}
.ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 144%;
  color: #2c264b;
}
td {
  padding: 16px 0 !important;
}
td:first-child {
  padding-left: 24px !important;
  padding-right: 16px !important;
}
.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #ffffff !important;
}

.ant-table-wrapper {
  border-top: 1px solid #efefef;
}

.odd-row {
  background: #fbfbfb !important;
}
.anticon-caret-up {
  height: 6.400000095367432px;
  width: 9px;
}
.anticon-caret-down {
  height: 6.400000095367432px;
  width: 9px;
}
.ant-table-column-sorter-inner {
  gap: 4.8px;
}
.ant-table-column-sorter-full {
  height: 14px !important;
}

// Drawer override
.ant-drawer-mask {
  background: rgba(83, 39, 140, 0.1) !important;
  backdrop-filter: blur(8px) !important;
}
.ant-drawer-content-wrapper {
  width: 480px !important;
  height: 100vh !important;
}
.ant-drawer-header {
  padding: 50px 40px 0 40px !important;
  border: none !important;
  .ant-drawer-header-title {
    gap: 20px;
    button {
      padding: 0 !important;
      margin: 0 !important;
      height: 15px !important;
      ion-icon {
        width: 24px !important;
        height: 24px !important;
      }
    }
    .ant-drawer-title {
      font-family: "General Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 144%;
      color: #181529;
    }
  }
}
.ant-drawer-body {
  padding: 0 0 0 0 !important;
  .ant-tabs-nav {
    margin-bottom: 0 !important;
    position: fixed !important;
    width: 100% !important;
    padding-top: 24px !important;
    z-index: 90 !important;
    background-color: white;
    .ant-tabs-tab {
      padding: 13px 4px 15px 4px !important;
      .ant-tabs-tab-btn {
        font-family: "General Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 12px !important;
        line-height: 144%;
        color: #121020 !important;
      }
    }
    .ant-tabs-nav-wrap {
      padding: 0 40px;
    }
  }
  .ant-tabs-content-holder {
    margin-top: 20% !important;
    padding: 0 40px !important;
    z-index: 3 !important;
  }
  .ant-tabs .ant-tabs-ink-bar {
    background-color: $primaryTextColor !important;
    height: 3px !important;
  }
}
.ant-drawer-wrapper-body {
  padding-bottom: 20px !important;
}

//custom text

.warning-text {
  color: #f04438 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
