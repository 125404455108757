.alerts-modal {
  padding: 0 40px !important;
  .alerts-modal-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    height: 778px;
    margin-top: 40px;

    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 16px;
      width: 100%;
      textarea {
        padding: 16px 24px;
        height: 144px;
        background: #f5f5f5;
        border-radius: 12px;
        outline: none;
        border: none;
        resize: none;
        &::placeholder {
          font-family: "General Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 144%;
          color: #2c264b;
        }
      }
    }
    p {
      margin: 0;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 144%;
      color: #121020;
    }
    button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      width: 100%;
      height: 48px;
      background: #f5f5f5;
      border-radius: 26px;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 144%;
      color: #121020;
      outline: none;
      border: none;
      cursor: pointer;
      &:hover {
        border: 1px solid #000080;
      }
    }
    button.selected {
      border: 1px solid #000080;
    }
  }

  .alerts-modal-btn__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    height: 40px !important;
    margin-top: 40px;
    button {
      height: 40px !important;
      &:first-child {
        width: 132px !important;
      }
      &:last-child {
        width: 94px !important;
      }
      span {
        font-size: 14px !important;
      }
    }
  }
}
