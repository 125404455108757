.Administrators {
  background-color: $bgColor;
  .sapa-administrators__container {
    margin-top: 24px;
    border-radius: 0 !important;
    border: none !important;
    height: fit-content !important;
    .ant-card-bordered {
      border: none !important;
    }
    .ant-card-body {
      padding: 0 !important;
    }
  }
}
