.button {
  width: 100%;
  height: 52px;
  border-radius: 30px;
  padding-inline: 20px !important;

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    border: none !important;
  }

  &__primary {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000080;
    &:hover {
      background: #000080 !important;
    }
  }

  &__transparent {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid $primaryTextColor;
    text-align: center;
    border-radius: 26px;
    height: 40px !important;
    padding: 0 !important;
    &:hover {
      background: #FFFBE7 !important;
    }
    & span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: $primaryTextColor;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 144%;
      i {
        display: block;
        width: fit-content;
        height: fit-content;
        margin: 0;
        height: 17px !important;
      }
    }
  }
}

.save-btn {
  width: 57px !important;
  height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000080;
  border-radius: 26px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 144%;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: $hoverColorDark;
  }
}
