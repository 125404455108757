.radio_card {
  margin-top: 20px;
  width: 100%;

  &__wrapper {
    border: 1px solid #f5f5f5;
    padding: 15px;
    border-radius: 12px;
    max-width: 45vw;
    //   max-height: 56px;
    cursor: pointer;
    position: relative;
    margin-top: 20px;
  }

  &__selected {
    border: 1px $primaryTextColor solid;
  }

  &__inner {
    display: flex;
    align-items: center;

    > .ant-radio-wrapper {
      position: absolute;
      right: 0;
    }
    > label {
      margin-left: 15px;
    }
    > ion-icon {
      position: absolute;
      font-size: 1.5vw;
      color: green;
      right: 10px;
    }
  }
}
