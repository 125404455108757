.Dashboard {
  background-color: $bgColor !important;
  .sapa-dashboard__topSection {
    margin-top: 24px;
    span {
      font-family: "General Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 144%;
      color: #181529;
    }
  }
  .sapa-dashboard__topSection {
    width: 100% !important;
  }
  .sapa-dashboard__lowerSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 480px;
    border: 1px solid #FFFBE7 !important;
    box-shadow: 0px 2px 40px rgba(177, 177, 177, 0.05);
    border-radius: 12px;
    .ant-card-body {
      padding: 34px 40px 32px 40px !important;
    }
  }
  .sapa-dashboard__legend_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chart-img {
      width: 171px;
      height: 20px;
    }
    .calendar-icon {
      width: 32px;
      height: 32px;
    }
  }
}

.dashboard-card__container {
  width: 25% !important;
  .ant-card-bordered {
    border: 1px solid #FFFBE7;
    box-shadow: 0px 2px 40px rgba(177, 177, 177, 0.05);
    border-radius: 12px;
  }
  .ant-card-body {
    padding: 20px !important;
  }
  .dashboard-card__container_inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.dashboard-card__topSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.dashboard-card__topSection_icon {
  width: 16px;
  height: 16px;
}
.sapa-dashboard-card__container {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.dashboard-card__btn-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
}
