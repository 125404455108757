

.sp-input {
    padding: 12px 24px;
    background-color: #F5F5F5 !important;
    border: none;
    border-radius: 32px;
}

.sp-input:focus {
    outline: none;
    border: 1px solid #000080;
    box-shadow: none;
}