.Transaction-modal {
.transaction-trace-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100% !important;
    .transaction-trace {
      background: #fafafa;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding: 24px;
      .transaction-trace__top-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 40px;
        span {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 8px;
          p {
            margin: 0 !important;
            font-family: "General Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            text-transform: uppercase;
            color: #56516f;
          }
        }
      }
      .transaction-trace__lower-section {
        .transaction-trace__lower-section_list {
          display: flex;
          flex-direction: column;
          gap: 14px;
        }
        .transaction-trace__lower-section-inner {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: fit-content;
          gap: 16.5px;
          .ant-avatar {
            align-items: center !important;
            height: fit-content !important;
            width: fit-content !important;
          }
          span {
            display: flex;
            flex-direction: column;
            p {
              margin: 0 !important;
            }
          }
        }
      }
    }
    .modal-list-dark-text {
      font-weight: 600 !important;
      color: #121020 !important;
      text-align: right !important;
      font-size: 14px !important;
    }
  }
}